import { Box } from '@components/primitives';
import { useSignals } from '@preact/signals-react/runtime';
import { sMarketplace } from '@signals/marketplace';
import { useMediaQuery } from 'react-responsive';

export const LandingHeader = () => {
  useSignals();

  const isSmallDevice = useMediaQuery({ maxWidth: 905 });
  const marketplace = sMarketplace.value;

  return (
    <Box
      css={{
        width: '100%',
        height: isSmallDevice ? 237 : 300,
      }}
    >
      <img
        src={marketplace?.imageBanner}
        alt={`${marketplace?.name} banner`}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};
