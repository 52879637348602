import { sMarketplace } from '@signals/marketplace';
import { Flex, Text, Grid } from './primitives';
import { styled } from '@stitches.config';
import { useMediaQuery } from 'react-responsive';
import Link from 'next/link';

const StyledImg = styled('img');

const SocialLinkNames = {
  twitter: 'Twitter',
  discord: 'Discord',
  website: 'Website',
};

const ResourceLinkNames = {
  tos: 'Terms of Use',
  pp: 'Privacy Policy',
};

export const Footer = () => {
  const marketplace = sMarketplace.value;
  const isSmallDevice = useMediaQuery({ maxWidth: 905 });

  const longName = `${marketplace?.name} marketplace`;
  const shortName = marketplace?.name;

  return (
    <Grid
      css={{
        gridTemplateColumns: isSmallDevice ? '1fr 1fr' : '2fr 1fr 0.25fr',
        gap: '$6',
        borderTop: '1px solid $borderColor',
        p: '$5',
      }}
    >
      <Flex direction='column' css={{ gap: '$5' }}>
        <StyledImg
          css={{
            width: 35,
            height: 35,
            borderRadius: '$radiusSmall',
          }}
          alt={longName}
          src={marketplace?.imageLogo}
        />
        <Flex direction='column' css={{ gap: '$2' }}>
          <Text style='subtitle2'>{isSmallDevice ? shortName : longName}</Text>
          <Text style='body2' color='subtle'>
            {new Date().getFullYear()}
          </Text>
        </Flex>
      </Flex>
      <Flex
        justify='between'
        direction={isSmallDevice ? 'column' : 'row'}
        css={{ gap: '$5' }}
      >
        <Flex direction='column' css={{ gap: '$2' }}>
          <Text style='subtitle2'>Resources</Text>
          {Object.entries(marketplace?.resourceLinks ?? {}).map(
            ([key, link]) => {
              const name =
                ResourceLinkNames[key as keyof typeof ResourceLinkNames];

              if (!link) {
                return (
                  <Text key={name} style='body2' color='subtle'>
                    {name}
                  </Text>
                );
              }

              return (
                <Link
                  key={name}
                  href={link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Text style='body2' color='subtle'>
                    {name}
                  </Text>
                </Link>
              );
            },
          )}
        </Flex>

        <Flex direction='column' css={{ gap: '$2' }}>
          <Text style='subtitle2'>Find Us Online</Text>
          {Object.entries(marketplace?.socialLinks ?? {}).map(([key, link]) => {
            const name = SocialLinkNames[key as keyof typeof SocialLinkNames];
            return (
              <Link
                key={name}
                href={link}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Text style='body2' color='subtle'>
                  {name}
                </Text>
              </Link>
            );
          })}
        </Flex>
      </Flex>
    </Grid>
  );
};
