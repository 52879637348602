import { Collection } from '@api/orderbook_api/v1/types.pb';
import { Flex, Grid, Text, Box, Button } from '@components/primitives';
import { useCollectionMetrics } from '@hooks/useCollectionMetrics';
import { styled } from '@stitches.config';
import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';

const StyledImg = styled('img');

interface Props {
  collection: Collection;
  orientation: 'horizontal' | 'vertical';
}

export const CollectionCard = ({ collection, orientation }: Props) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 905 });
  const { floorPrice, topOffer, vol24, avgSale24h } = useCollectionMetrics({
    collection,
  });

  const metricsSlot = isSmallDevice ? (
    <Grid
      css={{
        gridTemplateColumns: '1fr 1fr',
        gap: '$3 $4',
      }}
    >
      {floorPrice}
      {topOffer}
      {vol24}
      {avgSale24h}
    </Grid>
  ) : (
    <Flex
      css={{
        gap: '$3 $5',
      }}
    >
      {floorPrice}
      {topOffer}
      {vol24}
      {avgSale24h}
    </Flex>
  );

  const bannerSlot = (
    <Box css={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <StyledImg
        alt={collection?.config?.name}
        src={collection.config?.imageUrl}
        css={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );

  const nameSlot = (
    <Text
      css={{
        fontWeight: 600,
        lineHeight: 1.3,
        fontSize: isSmallDevice ? 16 : 36,
      }}
    >
      {collection?.config?.name?.toUpperCase()}
    </Text>
  );

  const shortDescriptionSlot = collection?.config?.shortDescription &&
    !isSmallDevice && (
      <Text
        css={{
          fontWeight: 500,
          fontSize: 15,
          wordWrap: 'anywhere',
        }}
      >
        {collection?.config?.shortDescription}
      </Text>
    );

  if (orientation === 'vertical' || isSmallDevice) {
    return (
      <Link href={`/collection/${collection.contractAddress}`}>
        <Flex
          direction='column'
          css={{
            backgroundColor: '$panelBg',
            overflow: 'hidden',
            borderRadius: '$radiusMedium',
          }}
        >
          <Box css={{ height: isSmallDevice ? 150 : 260 }}>{bannerSlot}</Box>
          <Flex
            direction='column'
            css={{
              p: isSmallDevice ? '$3 $2 $3 $3' : '20px 40px $5 40px',
              border: '1px solid $borderColor',
              borderTop: 'none',
              borderBottomLeftRadius: '$radiusMedium',
              borderBottomRightRadius: '$radiusMedium',
            }}
          >
            <Flex direction='column' css={{ gap: '$2' }}>
              {nameSlot}
              {shortDescriptionSlot}
              <Flex justify='between' css={{ pt: '$4' }}>
                <Box>
                  <Button>Explore</Button>
                </Box>
                {metricsSlot}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    );
  }

  return (
    <Link href={`/collection/${collection.contractAddress}`}>
      <Grid
        css={{
          overflow: 'hidden',
          borderRadius: '$radiusMedium',
          gridTemplateColumns: '1fr 1fr',
          backgroundColor: '$panelBg',
          height: isSmallDevice ? 200 : 463,
          boxShadow: '0px 4px 30px 0px #0000001A',
        }}
      >
        <Flex
          direction='column'
          justify='between'
          css={{
            p: isSmallDevice ? '$3 $2 $3 $3' : 40,
            borderTopLeftRadius: '$radiusMedium',
            borderBottomLeftRadius: '$radiusMedium',
            border: '1px solid $borderColor',
            borderRight: 'none',
          }}
        >
          <Flex direction='column' css={{ gap: '$2', maxWidth: '70%' }}>
            {nameSlot}
            {shortDescriptionSlot}
            <Box css={{ pt: '$4' }}>
              <Button>Explore</Button>
            </Box>
          </Flex>
          {metricsSlot}
        </Flex>
        {bannerSlot}
      </Grid>
    </Link>
  );
};
